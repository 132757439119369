<template>
  <!--Topbar-->
  <div class="header-main header-main-banner">
    <!-- Mobile Header -->
    <div class="sticky">
      <div class="horizontal-header clearfix">
        <div class="container">
          <a target="_self" 
            v-if="!$store.state.isLogin"
            :href="'/login?url=' + currentUrl"
            style="
              float: left;
              margin-right: 5px;
              font-size: 20px;
              margin-top: 10px;
            "
            ><span></span><i class="fa fa-sign-in me-1"></i
          ></a>
          <li
            class="dropdown"
            style="
              float: right;
              margin-right: 5px;
              font-size: 20px;
              margin-top: 10px;
            "
            v-if="$store.state.isLogin"
          >
            <a target="_self" href="" class="text-primary" data-bs-toggle="dropdown"
              ><i class="fa fa-home me-1"></i><span> </span
            ></a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <a target="_self" href="/profile" class="dropdown-item">
                <i class="dropdown-icon si si-user"></i> Profilim
              </a>
              <a target="_self" 
                href="/profile/experiences"
                class="dropdown-item"
                v-if="$store.state.user_type == 'EXPERT'"
              >
                <i class="dropdown-icon si si-diamond"></i> Hizmetlerim
            </a>
              <a target="_self"  href="/profile/appointments" class="dropdown-item">
                <i class="dropdown-icon si si-bell"></i> Randevularım
              </a>
              <a target="_self" 
                href="/profile/calendar"
                class="dropdown-item"
                v-if="$store.state.user_type == 'EXPERT'"
              >
                <i class="dropdown-icon si si-calendar"></i> Randevu Takvimi
            </a>
            </div>
          </li>
          <span class="smllogo">
            <img
              class="mobile-light-logo"
              src="https://terapivitrini.com/assets/images/brand/logo-primary.svg"
              style="width: 180px; height:100%;"
              alt="Terapi Vitrini"
            />
          </span>
          <li
            class="dropdown"
            style="
              float: right;
              margin-left: 5px;
              font-size: 20px;
              margin-top: 10px;
            "
            v-if="!$store.state.isLogin"
          >
            <a
              style="cursor: pointer"
              class="text-primary"
              data-bs-toggle="dropdown"
              ><i class="ion-person-add me-1"></i><span></span
            ></a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <a target="_self" href="/register" class="dropdown-item">
                <i class="dropdown-icon si si-user"></i> Danışan
              </a>
              <a target="_self"  href="/expert/register" class="dropdown-item">
                <i class="dropdown-icon si si-user"></i> Terapist
              </a>
              <a target="_self" href="/register/center/page" class="dropdown-item">
                <i class="dropdown-icon si si-badge"></i> Kurum
              </a>
            </div>
          </li>

          <a
            v-if="$store.state.isLogin"
            @click="logout"
            style="
              float: left;
              margin-left: 10px;
              font-size: 20px;
              margin-top: 10px;
            "
            ><i class="dropdown-icon si si-power" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
    <!-- Mobile Header -->

    <div class="horizontal-main clearfix horizontal-banner">
      <div class="horizontal-mainwrapper container clearfix">
        <div class="desktoplogo">
          <a target="_self" to="/"
            ><img
              class="mobile-light-logo"
              src="https://terapivitrini.com/assets/images/brand/logo-primary.svg"
              style="width: 235px;height: 100%;"
              alt="Terapi Vitrini"
          /></a>
        </div>
        <!--Nav-->
        <nav class="horizontalMenu clearfix d-md-flex">
          <ul
            class="custom"
            v-if="isLoad"
            style="margin-top: 34px; margin-right: 18px"
          >
            <li v-if="!$store.state.isLogin">
              <div
                style="width: 50px; height: 18px"
                class="skeleton text-primary"
              ></div>
            </li>
            <li v-if="!$store.state.isLogin">
              <div
                style="width: 50px; height: 18px"
                class="skeleton text-primary"
              ></div>
            </li>
          </ul>
          <ul class="horizontalMenu-list" v-if="!isLoad">
            <li class="dropdown" v-if="!$store.state.isLogin">
              <a :href="'/login?url=' + currentUrl" class="text-primary"
                ><i class="fa fa-sign-in me-1 text-primary"></i>
                <span>Giriş</span></a>
            </li>
            <li class="dropdown" v-if="!$store.state.isLogin">
              <a
                style="cursor: pointer"
                class="text-primary"
                data-bs-toggle="dropdown"
                ><i class="fa fa-user me-1 text-primary"></i
                ><span> Kayıt Ol</span></a
              >
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a target="_self" href="/register" class="dropdown-item">
                  <i class="dropdown-icon si si-user"></i> Danışan
                </a>
                <a target="_self" href="/expert/register" class="dropdown-item">
                  <i class="dropdown-icon si si-envelope"></i> Terapist
                </a>
                <a target="_self" href="/register/center/page" class="dropdown-item">
                <i class="dropdown-icon si si-badge"></i> Kurum
                </a>
              </div>
            </li>
            <li class="dropdown" v-if="$store.state.isLogin">
              <a
                href=""
                class="text-primary"
                data-bs-toggle="dropdown"
                style="height: 40px"
                ><i class="fa fa-home me-1 text-primary"></i
                ><span> Benim Sayfam</span></a
              >
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a target="_self" href="/profile" class="dropdown-item">
                  <i class="dropdown-icon si si-user"></i> Profilim
                </a>
                <a
                target="_self" 
                  href="/profile/experiences"
                  class="dropdown-item"
                  v-if="$store.state.user_type == 'EXPERT'"
                >
                  <i class="dropdown-icon si si-diamond"></i> Hizmetlerim
              </a>
                <a target="_self" href="/profile/appointments" class="dropdown-item">
                  <i class="dropdown-icon si si-bell"></i> Randevularım
                </a>
                <a
                target="_self" 
                  href="/profile/calendar"
                  class="dropdown-item"
                  v-if="$store.state.user_type == 'EXPERT'"
                >
                  <i class="dropdown-icon si si-calendar"></i> Randevu Takvimi
              </a>
              </div>
            </li>

            <li class="dropdown logout" v-if="$store.state.isLogin">
              <a
                style="cursor: pointer"
                @click="logout"
                class="text-primary"
                data-bs-toggle="dropdown"
                ><i class="dropdown-icon si si-power"></i
                ><span>Çıkış Yap</span></a
              >
            </li>
          </ul>
        </nav>
        <!--Nav-->
      </div>
    </div>
  </div>
</template>
<style>
.swal2-container {
  z-index: 9999;
}
.logout:hover i {
  color: white !important;
}
</style>
<script>
import Swal from "sweetalert2";
export default {
  name: "MenuList",
  components: {},
  created() {
    jQuery("body").removeClass("active");
    this.getMenu();
    this.currentUrl = window.location;
  },
  data() {
    return {
      menuList: [],
      open: false,
      currentUrl: "",
      isLoad: true,
      mobileSideBar: false,
    };
  },
  methods: {
    toogleSideBar() {
      if (this.mobileSideBar == false) {
        jQuery("body").toggleClass("active");
        jQuery('<div class="overlapblackbg"></div>').prependTo(
          ".horizontalMenu"
        );
        this.mobileSideBar = true;
      } else {
        jQuery(".overlapblackbg").on("click", function (e) {
          jQuery("body").removeClass("active");
        });
        this.mobileSideBar = false;
      }
    },
    getMenu() {
      let menuInfo = {
        location: "TOP",
      };
      this.$store.dispatch("menuGet", menuInfo).then((value) => {
        this.menuList = value.list;
        this.isLoad = false;
      });
    },
    logout() {
      Swal.fire({
        title: "Çıkış yapmak istediğinize emin misiniz?",
        text: "Terapi Vitrininden çıkış yapmak üzeresin, işlemi onaylıyor musun?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Çıkış Yap",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("logout");
        }
      });
    },
  },
  mounted() {
    jQuery("body").removeClass("active");
  },
};
</script>
