<template>
  <div class="main-home">
    <Header2 />
    <Search id="mainTop" />
    
    <Footer />
  </div>
</template>
<style scope>
.sptb {
  min-height: 120px;
}
#mainTop {
  background: #f6f7fb;
  width: 100%;
}

.ssd {
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
<script>
import Footer from "@/components/Footer2.vue";
import Header2 from "@/components/Header2.vue";
import Search from "../../components/Search.vue";
import StarPos from "../../components/StarPos.vue";

export default {
  metaInfo() {
    return {
      title: this.$store.state.setting.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.$store.state.setting.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = false;
    this.$store.state.footer = false;

    let serviceInfo = {
      parent_id: 0,
    };

    let _this = this;
    $(document).ready(function () {
      let content = document.getElementById("appcontent").innerHTML;
      _this.$store.dispatch("setMetaContent", [
        _this.$store.state.setting.title,
        _this.$store.state.setting.description,
        "",
        "",
        "",
        content,
      ]);
    });

    this.$store.dispatch("getBGimageList").then((value) => {
      this.imageList = value;
    });
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.serviceList = value;
      this.serviceListLoad = false;
    });
    this.$store.dispatch("homePage").then((value) => {
      this.homePage = value;
      this.isLoad = false;
      setTimeout(() => {
        this.loadED();
        let neg=200;
        if(screen.height<800){
          neg=0;
        }
        this.selectedComments = this.homePage.list.users[0].comments;
        document.getElementById("mainTop").style.height =
          screen.height - neg + "px";
      }, 100);
    });
  },
  data() {
    return {
      homePage: [],
      serviceList: [],
      imageList: [],
      isLoad: true,
      serviceListLoad: true,
      selectedComments: [],
      title: "",
      description: "",
    };
  },
  watch: {
    "$store.state.setting.title"() {
      let _this = this;
      $(document).ready(function () { 
      let content = document.getElementById("appcontent").innerHTML;
      _this.$store.dispatch("setMetaContent", [
        _this.$store.state.setting.title,
        _this.$store.state.setting.description,
        "",
        "",
        "",
        content,
      ]);
    });
    },
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
    },
    loadED() {
      var owl2 = $(".owl-carousel-icons");
      owl2.owlCarousel({
        margin: 25,
        loop: true,
        nav: true,
        autoplay: true,
        dots: false,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1300: {
            items: 3,
          },
        },
      });
      var owl = $(".testimonial-owl-carousel");
      owl.owlCarousel({
        loop: true,
        rewind: false,
        margin: 25,
        autoplay: true,
        animateIn: "fadeInDowm",
        animateOut: "fadeOutDown",
        autoplay: false,
        autoplayTimeout: 5000, // set value to change speed
        autoplayHoverPause: true,
        dots: false,
        nav: true,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
            nav: true,
          },
        },
      });
      let data = this;
      owl.on("changed.owl.carousel", function (event) {
        var current = event.item.index;
        var src = $(event.target)
          .find(".owl-item")
          .eq(current)
          .find(".item")
          .attr("setBgClr");
        data.selectedComments = data.homePage.list.users[src].comments;
      });
    },
  },
  components: { Search, StarPos, Header2, Footer, Footer },
  mounted() {
    document.getElementById("mainTop").style.height =
      screen.height - 200 + "px";
    addEventListener("resize", (event) => {
      document.getElementById("mainTop").style.height =
        screen.height - 200 + "px";
    });
    this.backToTop();
    var owl2 = $(".owl-carousel-icons");
    owl2.owlCarousel({
      margin: 25,
      loop: true,
      nav: true,
      autoplay: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1300: {
          items: 3,
        },
      },
    });
    var owl = $(".testimonial-owl-carousel");
    owl.owlCarousel({
      loop: true,
      rewind: false,
      margin: 25,
      autoplay: true,
      animateIn: "fadeInDowm",
      animateOut: "fadeOutDown",
      autoplay: false,
      autoplayTimeout: 5000, // set value to change speed
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
      },
    });
    let data = this;
    owl.on("changed.owl.carousel", function (event) {
      var current = event.item.index;
      var src = $(event.target)
        .find(".owl-item")
        .eq(current)
        .find(".item")
        .attr("setBgClr");
      data.selectedComments = data.homePage.list.users[src].comments;
    });
  },
};
</script>